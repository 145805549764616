
#close-setting {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
@media only screen and (max-width: 1200px) {
  #close-setting {
    display: block;
  }
}
